<!--  -->
<template>
  <div class="user">
    <!-- 面包屑导航 -->
    <el-card>
      <el-tabs>
        <el-tab-pane label="设置工作地点">
          <Jobsite />
        </el-tab-pane>
        <el-tab-pane label="设置公司名称">
          <Company />
        </el-tab-pane>
        <el-tab-pane label="设置班制管理">
          <Workshift />
        </el-tab-pane>
        <el-tab-pane label="设置用工形式">
          <Contract />
        </el-tab-pane>
      </el-tabs>
      <!-- 头 -->
    </el-card>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import Company from "@/components/allocation/company.vue";
import Contract from "@/components/allocation/contract.vue";
import Jobsite from "@/components/allocation/jobsite.vue";
import Workshift from "@/components/allocation/workshift.vue";

export default {
  //import引入的组件需要注入到对象中才能使用
  components: { Company, Contract, Jobsite, Workshift },
  data() {
    //这里存放数据
    return {};
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {},
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.getNav = this.$getNavn("/userlist");
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped>
.page {
  margin-top: 20px;
}
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand >>> .el-form-item__label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand >>> .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
</style>