<template>
  <div class="user">
    <!-- 修改用户 -->
    <el-dialog
      :visible.sync="dialogFormVisible"
      @close="userClose(form)"
      :append-to-body="true"
    >
      <div slot="title" class="dialog-footer">
        <div class="title" v-show="openState">添加工作地点</div>
        <div class="title" v-show="!openState">修改工作地点</div>
      </div>
      <el-form :model="form">
        <el-form-item label="地点名称" :label-width="formLabelWidth">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="地区编号" :label-width="formLabelWidth">
          <el-input v-model="form.staff_id" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="setAdd" v-show="openState"
          >添 加</el-button
        >
        <el-button type="primary" @click="setEdit" v-show="!openState"
          >修 改</el-button
        >
      </div>
    </el-dialog>
    <!-- 删除用户提示 -->
    <el-dialog :visible.sync="dialogVisible" width="30%" :append-to-body="true">
      <div slot="title" class="dialog-footer">
        <div class="title">删除工作地点</div>
      </div>
      <div style="margin-bottom: 10px">{{ userdelinfo.name }}</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="setDel">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 面包屑导航 -->
    <el-card>
      <!-- 头 -->
      <el-row :gutter="20">
        <el-col :span="5">
          <el-button type="primary" @click="addOpen()">添加</el-button>
        </el-col>
      </el-row>
      <!-- 表格 -->
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column prop="name" label="地点" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="staff_id" label="地点编号" show-overflow-tooltip>
        </el-table-column>
        <el-table-column width="180" label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini"
              icon="el-icon-edit"
              @click="editOpen(scope.row)"
            ></el-button>
            <el-button
              type="danger"
              size="mini"
              icon="el-icon-delete"
              @click="delOpen(scope.row)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
    </el-card>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {
  getList,
  getEdit,
  getAdd,
  getDel,
  getOption,
} from '@/api/user/jobsite.js'

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      dialogLog: {
        state: false,
      },
      spacer: '\u00a0\u00a0\u00a0',
      openState: true,
      //配置
      optionProps: {
        checkStrictly: true,
        value: 'id',
        label: 'name',
        children: 'son',
      },
      //总页数
      exp: {
        total: 0,
      },
      //当前页码
      getInfo: {},
      //导航
      nav: [],
      //单选列表
      radiolist: [
        {
          id: 1,
          name: '一级',
        },
        {
          id: 2,
          name: '二级',
        },
      ],
      //上级列表
      options: [],
      getNav: [],
      //即将删除的信息
      userdelinfo: {},
      // 添加信息表单
      form: {
        staff_id: '',
        name: '',
      },
      //表单名称长度
      formLabelWidth: '120px',
      //控制弹窗删除
      dialogVisible: false,
      //控制弹窗--新增修改
      dialogFormVisible: false,
      //搜索框
      inputSearch: '',
      //表格数据
      tableData: [],
      fileList: [],
      state: '0',
      list: [],
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    //打开添加
    addOpen() {
      this.openState = true
      this.dialogFormVisible = true
      this.form.cat_id = this.getInfo.cat_id
    },
    //打开编辑
    editOpen(row) {
      console.log(row)
      this.form = row
      this.openState = false
      this.dialogFormVisible = true
    },
    //编辑打开
    async setEdit() {
      let info = await getEdit(this.form)
      this.judge(info)
    },
    //删除打开
    delOpen(row, form) {
      this.dialogVisible = true
      this.userdelinfo = row
    },
    // 删除
    async setDel() {
      let info = await getDel({
        id: this.userdelinfo.id,
      })
      this.judge(info)
    },
    //搜索事件
    search(e) {
      console.log('搜索信息: ' + e)
    },
    //添加事件--提交事件
    async setAdd(e) {
      let info = await getAdd(this.form)
      this.judge(info)
    },
    judge(info) {
      if (info.data.code == 0) {
        this.$message({
          message: info.data.message,
          type: 'success',
        })
        this.dialogFormVisible = false
        this.dialogVisible = false
        this.catalogue()
      } else {
        this.$message({
          showClose: true,
          message: info.data.message,
          type: 'error',
        })
        return
      }
    },
    //添加事件 退出弹窗
    userClose(e) {
      this.form = {
        staff_id: '',
        name: '',
      }
    },
    //目录接口
    async catalogue() {
      let info = await getList()
      console.log(info.data)
      this.tableData = info.data.data
      let options = await getOption()
      this.options = options.data.data
      this.list = options.data.data
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.catalogue()
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
}
</script>

<style scoped>
.page {
  margin-top: 20px;
}
.title {
  font-size: 18px;
  font-weight: bold;
}
.set-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.set-page-left {
  color: #0000006d;
  font-size: 14px;
  font-weight: 400;
}
.border {
  display: flex;
}
.border-item {
  border: 1px solid #eee;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 6px;
  color: #666;
  margin-right: 5px;
}
.active {
  color: #909399 !important;
  background: #f4f4f5 !important;
}
.image {
  width: 120px;
  height: 120px;
  border-radius: 5px;
  display: flex;
  justify-items: center;
  align-items: center;
  background: #c0c4cc;
}
</style>
