import { post } from '@/utils/request'

export function getList(data) {
    return post('admin/company/list', data)
}
export function getAdd(data) {
    return post('admin/company/add', data)
}
export function getDel(data) {
    return post('admin/company/del', data)
}
export function getEdit(data) {
    return post('admin/company/edit', data)
}
export function getOption(data) {
    return post('admin/Jobsite/section', data)
}