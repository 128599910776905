import { post } from '@/utils/request'

export function getList(data) {
    return post('admin/Jobsite/list', data)
}
export function getAdd(data) {
    return post('admin/Jobsite/add', data)
}
export function getDel(data) {
    return post('admin/Jobsite/del', data)
}
export function getEdit(data) {
    return post('admin/Jobsite/edit', data)
}
export function getOption(data) {
    return post('admin/Jobsite/section', data)
}