import { post } from '@/utils/request'

export function getList(data) {
    return post('admin/contract/list', data)
}
export function getAdd(data) {
    return post('admin/contract/add', data)
}
export function getDel(data) {
    return post('admin/contract/del', data)
}
export function getEdit(data) {
    return post('admin/contract/edit', data)
}