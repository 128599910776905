import { post } from '@/utils/request'

export function getList(data) {
    return post('admin/workshift/list', data)
}

export function getAdd(data) {
    return post('admin/workshift/add', data)
}

export function getDel(data) {
    return post('admin/workshift/del', data)
}

export function getEdit(data) {
    return post('admin/workshift/edit', data)
}

export function getOption(data) {
    return post('admin/Jobsite/section', data)
}
export function getOption2(data) {
    return post('admin/company/section', data)
}